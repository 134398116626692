import bonfire from './assets/bonfire.jpg'
import tradingAI from './assets/crypto-trading.jpg';
import dataAi from './assets/data-ai.png';

import bayernlbJobLogo from './assets/bayernlb2.jpg';
import nysJobLogo from './assets/nys.png';
import anaDataJobLogo from './assets/ana-data-2.png';
import sculptorJobLogo from './assets/sculptor.png';
import { Link } from 'react-router-dom';

// =========================================================================================================================================
export const hobbiesArr = [
    {
        imgSrc: 'https://media.giphy.com/media/1569RvwRjejO2IcueA/giphy-downsized-large.gif',
        title: 'Trading',
        description: 'Started trading in 2017, mostly stocks and crypto. '
                    + "After some experience started trading options and spreads"
                    + "",
    },
    {
        imgSrc: 'https://media.giphy.com/media/3oEduKi6hUgSVAay4M/giphy.gif',
        title: 'Engineering',
        description: "Old friends introduced me into engineering, started small w/ fixing laptops. "
                    + "Progressing to building custom pc's and working on cars. "
                    + "In future, I want to get into 3D printing and arduino's."
                    + "",
    },
    {
        imgSrc: 'https://media.giphy.com/media/26gshn9NXKOMrnysU/giphy.gif',
        title: 'Photography',
        description: "Started up this hobby during Covid-19 pandemic, "
                    + "it was great to be able to document what life was like "
                    + "during the first major pandemic of the 20th century."
                    + "",
    },
    {
        imgSrc: 'https://media.giphy.com/media/wwg1suUiTbCY8H8vIA/giphy-downsized-large.gif',
        title: 'Coding',
        description: "Picked up this skill back in 2016 when I was researching different career paths. "
                    + "I love the development process, creating new technologies and the ownership of each project. "
                    + "",
    },];

// =========================================================================================================================================

export const projectsArr = [
    {
      imgSrc: bonfire,
      title: "Bonfire - Dating App",
      description: ["An astrology based dating app built in React/JS and powered by Google's Firebase for user authentification and data persistance. The app allows you to match, chat, and in the future host video calls. It also supports IP tracking, Check it out at ", <Link to={'https://www.meetatbonfire.com/'}>meetatbinfire.com/</Link>,
     ]

    },
    {
      imgSrc: tradingAI,
      title: 'Crypto Trading API',
      description: "Built to track the price action of your favorite crypto asset in real-time. "
                  + "Followed by keeping track of several moving averages marking points of convergence as potential buy/short opportunities. "
                  + "",

    },
    {
      imgSrc: dataAi,
      title: 'Unusual Volume Detector',
      description: "Shares change hands every day but some are more action-packed than others. "
                  + "This script gathers stock data over a 500-day period and returns 50-significant days of price movement. "
                  + "Notifying users of potential opportunities looming across the wave movements set by larger fish. "
                  + "",
    },
  ];

// =========================================================================================================================================

export const experienceArr = [
  {
    imgSrc: sculptorJobLogo,
    title: 'System Engineer',
    description: "Managed banking accounts and access control with external accounting firms. "
                + "Conducted security training, monitored VMs and servers, and automated tasks with Python scripts. "
                + "Managed VMs, servers, and firewalls, using SQL for performance tracking. "
                + " Improved ETL processes and data accuracy with SQL-based pipelines. "
                + "Conducted security training sessions and analyzed effectiveness with Looker."
                + "",
  },
  {
    imgSrc: nysJobLogo,
    title: 'Hardware Engineer',
    description: "Cut resolution time by 20% using TeamDynamix for efficient ticket monitoring. "
                + "Troubleshot and repaired electronic assets and devices, managing macOS, Windows, and Linux machines. "
                + "Trained new engineers on safety protocols and high-voltage electrical safety, ensuring compliance with standards. "
                + "Used business intelligence tools and Salesforce for tracking and analyzing ticket resolutions. "
                + "Analyzed resolution statistics and performance metrics to generate actionable insights. "
                + "",
        
  },
  {
    imgSrc: anaDataJobLogo,
    title: 'Software Engineer',
    description: "Developed ASP.NET web applications with CRUD functionality and automated ETL processes using SQL and C#. "
                + "Resolved UI defects and ensured a seamless user experience through collaboration with test and senior software engineers. "
                + "Automated data migration tasks using C# and built Windows services. "
                + "Utilized SQL for user authentication and data storage management in ASP.NET web applications. "
                + "",
  },
  {
    imgSrc: bayernlbJobLogo,
    title: 'Jr. Software Engineer',
    description: "Developed and automated PowerShell scripts for end-of-day report generation and batch processing. "
                + "Authored technical and functional application manuals to meet ECB audit standards. "
                + "Identified and addressed bugs and vulnerabilities in new banking applications, providing feedback for improvements. "
                + "",
  },
];

// =========================================================================================================================================


// =========================================================================================================================================
